<template>
  <v-container
    fill-height
  >
  <div style="width: 100%;">
      <center><v-icon style="font-size: 300px;color: #ccc;">mdi-emoticon-sad-outline</v-icon></center>
      <br />
      <center style="font-size:40px;color: #ccc;">No disponible</center>
      <center style="margin-top: 10px;">
        <v-btn
          outlined
          @click="handleGoTo"
          color="#ccc"
        >
          VOLVER A INTENTAR
        </v-btn>
      </center>
  </div>
  </v-container>
</template>

<script>
export default {
  methods: {
    handleGoTo () {
      this.$router.push('/')
    }
  }
}
</script>
